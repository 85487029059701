
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <h2>TESTSTTSS</h2>
    <div id="meetingSDKElement">
        Test
    </div>
  </Layout>
</template>
<script>
  import Layout from "../../layouts/main";
  import test from "@/app.config.json";
  // eslint-disable-next-line
  import { ZoomMtg } from '@zoomus/websdk';

  export default {
    page: {title: "EINSTUDIO-Zoom-Room",meta: [{name: "description"}]},
    components: {
      Layout
    },data() {
      return {
        title: "ZOOM APPLICATION",
        items: [{text: "Home"}, {text: "Learning",active: true}],
        baseUrl:test.baseUrl,user:[],
        sdkKey:'2UOFpn5sXSoRqa8HQbEm64OJN9wdmG4lCWUG',
        sdkSecret:'jWcgIl29Aff9Blas5GpRAkwvNHLn5T60SQtv',
        meetingNumber:"86415190765",
        passWord:"gHQp8H",
        userName:'mevlüt bostancı',
        userEmail:'',
        role:0,
        leaveUrl:'http://localhost:8081',
        registrantToken: ''
      };
    },created:function(){
      this.user = JSON.parse(localStorage.getItem('user'));
      //this.passWord=this.$route.params.pass;
      //this.meetingNumber=this.$route.params.id;
      this.userName= this.user.rol;
      this.joinMeeting(this);
    },methods: {
       /* eslint-disable */
       joinMeeting(payload)
       {
        let meetingSDKElement = document.getElementById('meetingSDKElement');
        ZoomMtg.setZoomJSLib('https://source.zoom.us/lib','/av');
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
        ZoomMtg.prepareWebSDK();
        ZoomMtg.generateSDKSignature({
          sdkKey:payload.sdkKey,
          sdkSecret:payload.sdkSecret,
          meetingNumber:payload.meetingNumber,
          role:payload.role,
          success:function(signature){

                document.getElementById('zmmtg-root').style.display = 'block'


             ZoomMtg.init({
               leaveUrl:payload.leaveUrl,
               isSupportAV: true,
               success:function(data) {
                 console.log(data)
                 ZoomMtg.join({
                    meetingNumber:payload.meetingNumber,
                    sdkKey:payload.sdkKey,
                    userName:payload.userName,
                    userEmail:payload.userEmail,
                    signature:signature.result,
                    tk:this.registrantToken,
                    success:function(data){
                       console.log(data);

                    },error:function(error){
                      console.log('---join error-->',error);
                    }
                 })
               },error:function(error){
                console.log('---Init error-->',error);
               }
             })
          },error:function(error){
            console.log('---son error-->',error)
          }
        })
      }
    }
  };
</script>